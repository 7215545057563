import * as React from 'react'
import styles from './index.module.scss'
import * as Icon from '../../../Icons';

export const PricingCard = ({module, term, bundle, handleModuleToggle,
  chmsRecords, changeChmsRecords,
  cardTransactions, changeCardTransactions,
  achTransactions, changeAchTransactions,
  mobileApps, changeMobileApps,
  streamingHours, changeStreamingHours,
  streamingStorage, changeStreamingStorage,
  webSites, changeWebSites,
  backgroundChecks, changeBackgroundChecks,
  advancedChecks, changeAdvancedChecks
}) => {
  let {icon} = '';

  switch(module.name) {
    case 'People':
      icon = `${Icon.People}`;
      break;
    case 'Giving':
      icon = `${Icon.Giving}`;
      break;
    case 'App Builder':
      icon = `${Icon.Mobile}`;
      break;
    case 'Streaming':
      icon = `${Icon.Streaming}`;
      break;
    case 'Websites':
      icon = `${Icon.Websites}`;
      break;
    case 'Protections':
      icon = `${Icon.Protections}`;
      break;
    default:
  }

  /*let chromium = false;
  if (window.navigator.userAgent.indexOf("Chrome/") != -1) {
    chromium = true;
  }*/

  React.useEffect(()=> {

  });

  return (
    <>
      <div className={`${styles.card} ${module.active ? styles.active : '' }`}>
        {(module.active && module.addon && term.code !== 'month' && bundle.name !== 'Create Your Own') &&
          <span className={styles.freeAddOn}>Free Software Add-On</span>
        }

        <div className={styles.icon}>
          <img src={icon} alt="Icon" />
        </div>

        <div className={styles.details}>
          <div className={styles.name}>{module.name !== 'Protections' ? module.name : 'Background Screening'}</div>
          {(module.addon || bundle.name !== 'Foundation' && module.name === 'Websites') &&
            <div className={`${styles.starting} ${module.addon === true && term.code !== 'month' && bundle.name !== 'Create Your Own' ? 'addon' : ''}`}>
              {(!module.active && bundle.name === 'Create Your Own' || !module.active && module.addon !== true || term.code === 'month' && !module.active) && <>Starting at </>}
              <strong>${module[term.code]}</strong>/month
            </div>
          }
        </div>

        {(!module.active && module.addon && term.code !== 'month' && bundle.name !== 'Create Your Own') ?
          <span className={`${styles.freeAddOn} not-active`}>Free Software Add-On</span>
        :
         !module.active && <span></span>
        }

        {module.active &&
          <div className={styles.usage}>
            <div className={styles.instructions}>
              {(module.active && module.name !== 'App Builder') &&
                <>Adjust sliders to estimate monthly usage fees.</>
              }
            </div>

            {module.name === 'People' &&
              <>
                <div className={styles.item}>
                  <div className={styles.slider} style={{background: 'linear-gradient(to right, #0f3e80 0%, #0f3e80 '+ ((chmsRecords.usage/chmsRecords.max) * 100) +'%, #F1F0F0 ' + ((chmsRecords.usage/chmsRecords.max) * 100) + '%, #F1F0F0 100%)'}}>
                    <span style={{flex: 'calc('+chmsRecords.free+'/'+chmsRecords.max+')'}} />
                    <input type="range" min={chmsRecords.free} max={chmsRecords.max} value={chmsRecords.usage} onChange={changeChmsRecords} className={`${chmsRecords.usage <= chmsRecords.free && 'free'}`} />
                  </div>
                  <div className={styles.label}>
                    <input type="number" min={chmsRecords.free} max={chmsRecords.max} value={chmsRecords.usage} onChange={changeChmsRecords} className={chmsRecords.usage <= chmsRecords.free && 'free'} />
                    CHMS Records
                  </div>
                </div>

                <div className={styles.legal}>1,000 records included. ${chmsRecords.rate} per additional record.</div>
              </>
            }

            {module.name === 'App Builder' &&
              <>
                <div className={styles.item}>
                  <div className={styles.slider} style={{background: 'linear-gradient(to right, #0f3e80 0%, #0f3e80 '+ ((mobileApps.usage/mobileApps.max) * 100) +'%, #F1F0F0 ' + ((mobileApps.usage/mobileApps.max) * 100) + '%, #F1F0F0 100%)'}}>
                    <span style={{flex: 'calc('+mobileApps.free+'/'+mobileApps.max+')', borderRadius: '20px'}} />
                    {/*<input type="range" min={mobileApps.free} max={mobileApps.max} value={mobileApps.usage} onChange={changeMobileApps} className={mobileApps.usage <= mobileApps.free && 'free'} />*/}
                  </div>
                  <div className={styles.label}>
                    <label className={mobileApps.usage <= mobileApps.free && 'free'}>{mobileApps.usage}</label>
                    Mobile Apps
                  </div>
                </div>
                
                <div className={styles.legal}>{mobileApps.free} app included. ${mobileApps.rate} per additional app a month.</div>
              </>
            }

            {module.name === 'Websites' &&
              <>
                <div className={styles.item}>
                  <div className={styles.slider} style={{background: 'linear-gradient(to right, #0f3e80 0%, #0f3e80 '+ ((webSites.usage/webSites.max) * 100) +'%, #F1F0F0 ' + ((webSites.usage/webSites.max) * 100) + '%, #F1F0F0 100%)'}}>
                    <span style={{flex: 'calc('+webSites.free+'/'+webSites.max+')'}} />
                    <input type="range" min={webSites.free} max={webSites.max} value={webSites.usage} onChange={changeWebSites} className={webSites.usage <= webSites.free && 'free'} />
                  </div>
                  <div className={styles.label}>
                    <input type="number" min={webSites.free} max={webSites.max} value={webSites.usage} onChange={changeWebSites} className={webSites.usage <= webSites.free && 'free'} />
                    Web Sites
                  </div>
                </div>
                
                <div className={styles.legal}>{webSites.free} website included. ${webSites.rate} per additional website a month.</div>
              </>
            }

            {module.name === 'Giving' &&
              <>
                <div className={styles.item}>
                  <div className={styles.slider} style={{background: 'linear-gradient(to right, #0f3e80 0%, #0f3e80 '+ ((cardTransactions.usage/cardTransactions.max) * 100) +'%, #F1F0F0 ' + ((cardTransactions.usage/cardTransactions.max) * 100) + '%, #F1F0F0 100%)'}}>
                    <input type="range" min={cardTransactions.free} max={cardTransactions.max} value={cardTransactions.usage} onChange={changeCardTransactions} />
                  </div>
                  <div className={styles.label}>
                    <input type="number" min={cardTransactions.free} max={cardTransactions.max} value={cardTransactions.usage} onChange={changeCardTransactions} />
                    Card Transactions
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.slider} style={{background: 'linear-gradient(to right, #0f3e80 0%, #0f3e80 '+ ((achTransactions.usage/achTransactions.max) * 100) +'%, #F1F0F0 ' + ((achTransactions.usage/achTransactions.max) * 100) + '%, #F1F0F0 100%)'}}>
                    <input type="range" min={achTransactions.free} max={achTransactions.max} value={achTransactions.usage} onChange={changeAchTransactions} />
                  </div>
                  <div className={styles.label}>
                    <input type="number" min={achTransactions.free} max={achTransactions.max} value={achTransactions.usage} onChange={changeAchTransactions} />
                    ACH Transactions
                  </div>
                </div>
                
                <div className={styles.legal}>2.75% + ${cardTransactions.rate} per debit/credit transaction - 1.00% + ${achTransactions.rate} per ACH transaction + Other Service Delivery Fees</div>
              </>
            }

            {module.name === 'Streaming' &&
              <>
                <div className={styles.item}>
                  <div className={styles.slider} style={{background: 'linear-gradient(to right, #0f3e80 0%, #0f3e80 '+ ((streamingHours.usage/streamingHours.max) * 100) +'%, #F1F0F0 ' + ((streamingHours.usage/streamingHours.max) * 100) + '%, #F1F0F0 100%)'}}>
                    <span style={{flex: 'calc('+streamingHours.free+'/'+streamingHours.max+')'}} />
                    <input type="range" min={streamingHours.free} max={streamingHours.max} value={streamingHours.usage} onChange={changeStreamingHours} className={streamingHours.usage <= streamingHours.free && 'free'} />
                  </div>
                  <div className={styles.label}>
                    <input type="number" min={streamingHours.free} max={streamingHours.max} value={streamingHours.usage} onChange={changeStreamingHours} className={streamingHours.usage <= streamingHours.free && 'free'} />
                    Live Stream Hours
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.slider} style={{background: 'linear-gradient(to right, #0f3e80 0%, #0f3e80 '+ ((streamingStorage.usage/streamingStorage.max) * 100) +'%, #F1F0F0 ' + ((streamingStorage.usage/streamingStorage.max) * 100) + '%, #F1F0F0 100%)'}}>
                    <span style={{flex: 'calc('+streamingStorage.free+'/'+streamingStorage.max+')'}} />
                    <input type="range" min={streamingStorage.free} max={streamingStorage.max} value={streamingStorage.usage} onChange={changeStreamingStorage} className={streamingStorage.usage <= streamingStorage.free && 'free'} />
                  </div>
                  <div className={styles.label}>
                    <input type="number" min={streamingStorage.free} max={streamingStorage.max} value={streamingStorage.usage} onChange={changeStreamingStorage} className={streamingStorage.usage <= streamingStorage.free && 'free'} />
                    GB of Storage
                  </div>
                </div>
                
                <div className={styles.legal}>Included: {streamingHours.free} hours of streaming and {streamingStorage.free} GB of storage per month. Any additional usage will incur charges of ${streamingHours.rate} per extra hour of streaming and ${streamingStorage.rate} per additional GB of monthly storage.</div>
              </>
            }

            {module.name === 'Protections' &&
              <>
                <div className={styles.item}>
                  <div className={styles.slider} style={{background: 'linear-gradient(to right, #0f3e80 0%, #0f3e80 '+ ((backgroundChecks.usage/backgroundChecks.max) * 100) +'%, #F1F0F0 ' + ((backgroundChecks.usage/backgroundChecks.max) * 100) + '%, #F1F0F0 100%)'}}>
                    <input type="range" min={backgroundChecks.free} max={backgroundChecks.max} value={backgroundChecks.usage} onChange={changeBackgroundChecks} />
                  </div>
                  <div className={styles.label}>
                    <input type="number" min={backgroundChecks.free} max={backgroundChecks.max} value={backgroundChecks.usage} onChange={changeBackgroundChecks} />
                    Background Checks
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.slider} style={{background: 'linear-gradient(to right, #0f3e80 0%, #0f3e80 '+ ((advancedChecks.usage/advancedChecks.max) * 100) +'%, #F1F0F0 ' + ((advancedChecks.usage/advancedChecks.max) * 100) + '%, #F1F0F0 100%)'}}>
                    <span style={{flex: 'calc('+advancedChecks.free+'/'+advancedChecks.max+')'}} />
                    <input type="range" min={advancedChecks.free} max={advancedChecks.max} value={advancedChecks.usage} onChange={changeAdvancedChecks} className={advancedChecks.usage <= advancedChecks.free && 'free'} />
                  </div>
                  <div className={styles.label}>
                    <input type="number" min={advancedChecks.free} max={advancedChecks.max} value={advancedChecks.usage} onChange={changeAdvancedChecks} className={advancedChecks.usage <= advancedChecks.free && 'free'} />
                    Advanced Background Checks
                  </div>
                </div>
              </>
            }

          </div>
        }

        {!module.active &&
          <div className={styles.button}>
            <button onClick={() => handleModuleToggle(module.name)}>Add</button>
          </div>
        }

        {(module.active && module.addon === true || bundle.name !== 'Foundation' && module.active && module.name === 'Websites') &&
          <div className={styles.remove} onClick={() => handleModuleToggle(module.name)} />
        }
      </div>
    </>
  )
}
