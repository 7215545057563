import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import fetchJsonp from 'fetch-jsonp'

const FormDemo = ({brand}) => {
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    state: '',
    weekly_attendance: '',
    mb_t_and_c: true,
    referring_page: window.location.href,
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    utm_term: ''
  };
  
  let validationShape = {
    firstname: Yup.string()
      .required('Required'),
    lastname: Yup.string()
      .required('Required'),
    email: Yup.string()
      .email('Must be a valid email')
      .required('Required'),
    phone: Yup.string()
      .required('Required'),
    company: Yup.string()
      .required('Required'),
    state: Yup.string()
      .required('Required'),
    mb_t_and_c: Yup.bool()
      .oneOf([true], 'Required')
  };

  const validationSchema = Yup.object().shape(validationShape);

  let handler = '';
  if (brand === 'MinistryLINQ' || brand === 'GivingKiosk' || brand === 'SimpleGive' || brand === 'eGiving' || brand === 'EFT Plus') {
    handler = 'https://go.ministrybrands.com/l/991222/2023-11-20/2hh2t';
  } else if (brand === 'NCS Services' || brand === 'BlueFire' || brand === 'Mogiv' || brand === 'Clover' || brand === 'Clover Give' || brand === 'GivingBase') {
    handler = 'https://go.ministrybrands.com/l/991222/2023-11-20/2hh3j';
  } else if (brand === 'e360 Giving' || brand === 'eGive USA') {
    handler = 'https://go.ministrybrands.com/l/991222/2023-11-20/2hh3m';
  }

  const handlePardot = (values) => {
    //alert(`${handler}?${new URLSearchParams(values).toString()}`);
    fetchJsonp(`${handler}?${new URLSearchParams(values).toString()}`, {
      jsonpCallbackFunction: 'callbackPardot'
    })
    .then(function(response) {
      return response.json()
    }).then(function(json) {
      console.log('parsed json', json)
    }).catch(function(ex) {
      console.log('parsing failed', ex)
          
      setTimeout(function() {
        document.getElementById('BrandUniDemoForm').innerHTML = 'Someone from our Sales Team will be in touch shortly to get you started.</p>';
      }, 250);
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handlePardot(values);
      }}
    >
      {({ errors, touched }) => (
        <Form className="Form">
          <div>
            <label htmlFor="firstname">First Name</label>
            <Field name="firstname" type="text" placeholder="First Name" />
            <ErrorMessage name="firstname" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="lastname">Last Name</label>
            <Field name="lastname" type="text" placeholder="Last Name" />
            <ErrorMessage name="lastname" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="email">Email</label>
            <Field name="email" type="email" placeholder="Email" />
            <ErrorMessage name="email" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="phone">Phone</label>
            <Field name="phone" type="text" placeholder="Phone" />
            <ErrorMessage name="phone" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="company">Organization Name</label>
            <Field name="company" type="text" placeholder="Organization Name" />
            <ErrorMessage name="company" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="state">State</label>
            <Field name="state" as="select">
              <option value="">State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </Field>
            <ErrorMessage name="state" render={msg => <span className="error">{msg}</span>} />
          </div>
          
          <div>
            <label htmlFor="weekly_attendance">Total Weekly Attendance</label>
            <Field name="weekly_attendance" as="select">
              <option value="">Total Weekly Attendance</option>
              <option value="100 or less">100 or less</option>
              <option value="101-250">101-250</option>
              <option value="251-500">251-500</option>
              <option value="500+">500+</option>
            </Field>
            <ErrorMessage name="weekly_attendance" render={msg => <span className="error">{msg}</span>} />
          </div>
          
          <div>
            <label htmlFor="mb_t_and_c" style={{display: 'block', height: 'auto', visibility: 'visible', marginBottom: '1rem'}}>
              <Field name="mb_t_and_c" type="checkbox" style={{marginRight: '0.5em'}} />
              I acknowledge that Ministry Brands will use my information to contact me about relevant content, products and services and that I can unsubscribe from these emails at any time.
            </label>
            <ErrorMessage name="mb_t_and_c" render={msg => <span className="error">{msg}</span>} />
          </div>

          <Field name="utm_campaign" type="hidden" />
          <Field name="utm_source" type="hidden" />
          <Field name="utm_medium" type="hidden" />
          <Field name="utm_content" type="hidden" />
          <Field name="utm_term" type="hidden" />

          <button type="submit" className="Btn">Submit</button>
        </Form>
      )}
    </Formik>
  );
};

export default FormDemo;