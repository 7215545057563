import * as React from 'react'
import { PricingCard } from './Card'
import styles from './index.module.scss'

const AmplifyPricing = () => {
  const terms = [{
    name: '36 Months',
    code: 'month36',
    active: true
  }, {
    name: '12 Months',
    code: 'month12',
    active: false
  }, {
    name: 'Month to Month',
    code: 'month',
    active: false
  }];

  const [ termList, setTermList ] = React.useState(terms);

  const bundles = [{
    name: 'Core',
    modules: ['People', 'App Builder'],
    month36: 75,
    month12: 87,
    month: 98,
    active: false
  }, {
    name: 'Foundation',
    modules: ['People', 'App Builder', 'Websites'],
    month36: 99,
    month12: 114,
    month: 129,
    popular: true,
    active: true
  }, {
    name: 'Create Your Own',
    modules: [],
    month36: 0,
    month12: 0,
    month: 0,
    active: false
  }];

  const [ bundleList, setBundleList ] = React.useState(bundles);

  const modules = [{
    name: 'People',
    month36: 49,
    month12: 55,
    month: 59,
    usage: ['chmsRecords'],
    active: true
  }, {
    name: 'App Builder',
    month36: 29,
    month12: 35,
    month: 39,
    usage: ['mobileApps'],
    active: true
  }, {
    name: 'Websites',
    month36: 29,
    month12: 35,
    month: 39,
    usage: ['webSites'],
    isAddon: false,
    active: true
  }, {
    name: 'Giving',
    month36: 19,
    month12: 25,
    month: 29,
    usage: ['cardTransactions', 'achTransactions'],
    active: false
  }, {
    name: 'Streaming',
    month36: 29,
    month12: 35,
    month: 39,
    usage: ['streamingHours', 'streamingStorage'],
    active: false
  }, {
    name: 'Protections',
    month36: 19,
    month12: 25,
    month: 29,
    usage: ['backgroundChecks', 'advancedChecks'],
    active: false
  }];

  const [ moduleList, setModuleList ] = React.useState(modules);

  const [ chmsRecords, setChmsRecords ] = React.useState({
    usage: 1000,
    free: 1000,
    max: 25000,
    rate: 0.12
  });

  const [ cardTransactions, setCardTransactions ] = React.useState({
    usage: 0,
    free: 0,
    max: 1500,
    rate: 0.42
  });

  const [ achTransactions, setAchTransactions ] = React.useState({
    usage: 0,
    free: 0,
    max: 250,
    rate: 0.42
  });

  const [ mobileApps, setMobileApps ] = React.useState({
    usage: 1,
    free: 1,
    max: 1,
    rate: 10
  });

  const [ streamingHours, setStreamingHours ] = React.useState({
    usage: 4,
    free: 4,
    max: 32,
    rate: 6
  });

  const [ streamingStorage, setStreamingStorage ] = React.useState({
    usage: 60,
    free: 60,
    max: 480,
    rate: (0.10).toFixed(2)
  });

  const [ webSites, setWebSites ] = React.useState({
    usage: 1,
    free: 1,
    max: 10,
    rate: 10
  });

  const [ backgroundChecks, setBackgroundChecks ] = React.useState({
    usage: 0,
    free: 0,
    max: 210,
    rate: 10.95
  });

  const [ advancedChecks, setAdvancedChecks ] = React.useState({
    usage: 2,
    free: 2,
    max: 10,
    rate: 21.95
  });
  
  const handleTermToggle = (name) => {
    let mapped = termList.map(term => {
      return term.name === name ? { ...term, active: true } : { ...term, active: false };
    });
    setTermList(mapped);
  }
  
  const handleBundleToggle = (name) => {
    let mapped = bundleList.map(bundle => {
      return bundle.name === name ? { ...bundle, active: true } : { ...bundle, active: false };
    });
    setBundleList(mapped);

    moduleList.map(module => {
      if (!mapped.find(bundle => bundle.active === true).modules.includes(module.name)) {
        module.active = false;
        if (module.isAddon !== false) {
          module.addon = true;
        }
      } else {
        module.active = true;
        module.addon = false;
      }
    })
  }
  
  const handleModuleToggle = (name) => {
    let mapped = moduleList.map(module => {
      return module.name === name ? { ...module, active: !module.active } : module;
    });
    setModuleList(mapped);

    //* Reset usage ranges - MAYBE NOT NEEDED?
    /*setAdvancedChecks({
      usage: 2,
      free: 2,
      max: 10
    })*/
  }

  const changeChmsRecords = (e) => {
    setChmsRecords({...chmsRecords, usage: e.target.value})
  };

  const changeCardTransactions = (e) => {
    setCardTransactions({...cardTransactions, usage: e.target.value})
  };

  const changeAchTransactions = (e) => {
    setAchTransactions({...achTransactions, usage: e.target.value})
  };

  const changeMobileApps = (e) => {
    setMobileApps({...mobileApps, usage: e.target.value})
  };

  const changeStreamingHours = (e) => {
    setStreamingHours({...streamingHours, usage: e.target.value})
  };

  const changeStreamingStorage = (e) => {
    setStreamingStorage({...streamingStorage, usage: e.target.value})
  };

  const changeWebSites = (e) => {
    setWebSites({...webSites, usage: e.target.value})
  };

  const changeBackgroundChecks = (e) => {
    setBackgroundChecks({...backgroundChecks, usage: e.target.value})
  };

  const changeAdvancedChecks = (e) => {
    setAdvancedChecks({...advancedChecks, usage: e.target.value})
  };

  const twa = React.useRef(0);

  const handleTotalWeeklyAttendance = () => {
    let twaValue = twa.current.value

    let twaChmsRecords = 1000;
    if (twaValue > 250) {
      twaChmsRecords = (twaValue * 4)
    }

    let twaWebSites = 1;
    if (twaValue > 1000) {
      twaWebSites = 2
    }

    setChmsRecords({
      ...chmsRecords,
      usage: twaChmsRecords
    })

    setCardTransactions({
      ...cardTransactions,
      usage: Math.trunc(((twaValue * 4) * 0.05) * 0.30)
    })

    setAchTransactions({
      ...achTransactions,
      usage: Math.trunc(((twaValue * 4) * 0.05) * 0.05)
    })

    setMobileApps({...mobileApps, usage: 1})
    setStreamingHours({...streamingHours, usage: 4})
    setStreamingStorage({...streamingStorage, usage: 60})

    setWebSites({
      ...webSites,
      usage: twaWebSites
    })

    setBackgroundChecks({
      ...backgroundChecks,
      usage: Math.ceil((twaValue * 0.25) / 12)
    })

    setAdvancedChecks({
      ...advancedChecks,
      usage: Math.ceil((twaValue / 100) / 12)
    })
  }

  console.log(chmsRecords)

  React.useEffect(()=> {
    let stickyPricing = document.getElementById('stickyPricing');

    document.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        stickyPricing.classList.add('is-sticky')
      } else {
        stickyPricing.classList.remove('is-sticky')
      }
    });

    let popularBundle = bundleList.find((bundle) => bundle.popular === true);
    handleBundleToggle(popularBundle.name)
  }, []);

  let activeTerm = termList.find((term) => term.active === true);

  let activeBundle = bundleList.find((bundle) => bundle.active === true);

  let activeModules = moduleList.filter((module) => module.active === true);

  // Formulas
  React.useEffect(()=> {
    let bundlePrice = document.getElementById('bundlePrice');
    let estimatedUsage = document.getElementById('estimatedUsage');
    let estimatedTotal = document.getElementById('estimatedTotal');

    // Usage
    let totalUsage = 0;

    const usageCalc = (type) => (type.usage - type.free) * type.rate;

    // People
    if (moduleList[0].active === true) {
      totalUsage += usageCalc(chmsRecords)
    }

    // App Builder
    if (moduleList[1].active === true) {
      totalUsage += usageCalc(mobileApps)
    }

    // Websites
    if (moduleList[2].active === true) {
      totalUsage += usageCalc(webSites)
    }

    // Giving
    if (moduleList[3].active === true) {
      totalUsage += usageCalc(cardTransactions)
      totalUsage += usageCalc(achTransactions)
    }

    let givingFees = 0;
    if (activeModules.some(module => module.name === 'Giving' && module.active === true)) {
      givingFees = 19.95 + 9.99 + 9.00
    }

    // Streaming
    if (moduleList[4].active === true) {
      totalUsage += usageCalc(streamingHours)
      totalUsage += usageCalc(streamingStorage)
    }

    // Protections
    if (moduleList[5].active === true) {
      totalUsage += usageCalc(backgroundChecks)
      totalUsage += usageCalc(advancedChecks)
    }

    let termCode = activeTerm.code;

    // Bundle
    let bundleCost = activeBundle[termCode];

    // Module
    let moduleCost = 0;
    activeModules.forEach(module => {
      if (module.addon === true && termCode === 'month') {
        moduleCost += module[termCode]
      } else if (activeBundle.name === 'Core' && module.isAddon === false) {
        moduleCost += module[termCode]
      } else if (activeBundle.name === 'Create Your Own') {
        moduleCost += module[termCode]
      }
    })

    let totalBundle = bundleCost + moduleCost;
    
    bundlePrice.innerHTML = totalBundle;
    estimatedUsage.innerHTML = Math.ceil(totalUsage).toLocaleString();
    estimatedTotal.innerHTML = Math.ceil(totalBundle + totalUsage + givingFees).toLocaleString();
  })
  // End Formulas

  return (
    <>

      <section className={`${styles.hero} section`}>
        <div className="container" style={{textAlign: 'center'}}>
          <div className={styles.label}>
            Customizable Pricing Based On Your Needs
          </div>
          <div className={styles.headline}>
            <h1>Empower Your Healthy Church Today and Tomorrow with Ministry Brand's Amplify Bundles</h1>
          </div>
        </div>
      </section>

      <section className={`${styles.terms} section`}>
        <div className="container">
          <div className={styles.toggle}>
            <ul>
              {termList.map((term, i) => (
                <li key={i} onClick={() => handleTermToggle(term.name)} className={term.active ? 'is-active' : ''}>{term.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className={`${styles.twa} section`}>
        <div className="container">
          <div className={styles.frame}>
            <label htmlFor="twa">
              Weekly Attendance
            </label>
            <input type="number" name="twa" min="1" max="9999" defaultValue="500" onChange={handleTotalWeeklyAttendance} ref={twa} />
            <div className="">
              <button onClick={handleTotalWeeklyAttendance}>Estimate Usage</button>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.bundles} section`}>
        <div className="container">
          <div className={styles.toggle}>
            <ul>
            {bundleList.map((bundle, i) => (
              <li key={i} onClick={() => handleBundleToggle(bundle.name)} className={bundle.active ? 'is-active' : undefined}>{bundle.name} Bundle {bundle.popular === true ? <span className="popular">Most Popular</span> : undefined}</li>
            ))}
            </ul>
          </div>
        </div>
      </section>

      <section className={`${styles.pricing} section`}>
        <div className="container">
          <div className={styles.frame}>
            {activeBundle.name === 'Core' &&
              <>
                <p>What's included</p>

                <PricingCard
                  module={moduleList[0]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  chmsRecords={chmsRecords} changeChmsRecords={changeChmsRecords}
                />

                <PricingCard
                  module={moduleList[1]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  mobileApps={mobileApps} changeMobileApps={changeMobileApps}
                />

                <p>Additional add-ons</p>

                <PricingCard
                  module={moduleList[2]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  webSites={webSites} changeWebSites={changeWebSites}
                />

                <PricingCard
                  module={moduleList[3]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  cardTransactions={cardTransactions} changeCardTransactions={changeCardTransactions}
                  achTransactions={achTransactions} changeAchTransactions={changeAchTransactions}
                />

                <PricingCard
                  module={moduleList[4]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  streamingHours={streamingHours} changeStreamingHours={changeStreamingHours}
                  streamingStorage={streamingStorage} changeStreamingStorage={changeStreamingStorage}
                />

                <PricingCard
                  module={moduleList[5]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  backgroundChecks={backgroundChecks} changeBackgroundChecks={changeBackgroundChecks}
                  advancedChecks={advancedChecks} changeAdvancedChecks={changeAdvancedChecks}
                />
              </>
            }
            
            {activeBundle.name === 'Foundation' &&
              <>
                <p>What's included</p>

                <PricingCard
                  module={moduleList[0]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  chmsRecords={chmsRecords} changeChmsRecords={changeChmsRecords}
                />

                <PricingCard
                  module={moduleList[1]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  mobileApps={mobileApps} changeMobileApps={changeMobileApps}
                />

                <PricingCard
                  module={moduleList[2]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  webSites={webSites} changeWebSites={changeWebSites}
                />

                <p>Additional add-ons</p>

                <PricingCard
                  module={moduleList[3]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  cardTransactions={cardTransactions} changeCardTransactions={changeCardTransactions}
                  achTransactions={achTransactions} changeAchTransactions={changeAchTransactions}
                />

                <PricingCard
                  module={moduleList[4]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  streamingHours={streamingHours} changeStreamingHours={changeStreamingHours}
                  streamingStorage={streamingStorage} changeStreamingStorage={changeStreamingStorage}
                />

                <PricingCard
                  module={moduleList[5]} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                  backgroundChecks={backgroundChecks} changeBackgroundChecks={changeBackgroundChecks}
                  advancedChecks={advancedChecks} changeAdvancedChecks={changeAdvancedChecks}
                />
              </>
            }
            
            {activeBundle.name === 'Create Your Own' &&
              <>
                <p>Add Modules to create your bundle</p>
                {moduleList.map((module, i) => (
                  <PricingCard
                    key={i} module={module} term={activeTerm} bundle={activeBundle} handleModuleToggle={handleModuleToggle}
                    chmsRecords={chmsRecords} changeChmsRecords={changeChmsRecords}
                    cardTransactions={cardTransactions} changeCardTransactions={changeCardTransactions}
                    achTransactions={achTransactions} changeAchTransactions={changeAchTransactions}
                    mobileApps={mobileApps} changeMobileApps={changeMobileApps}
                    streamingHours={streamingHours} changeStreamingHours={changeStreamingHours}
                    streamingStorage={streamingStorage} changeStreamingStorage={changeStreamingStorage}
                    webSites={webSites} changeWebSites={changeWebSites}
                    backgroundChecks={backgroundChecks} changeBackgroundChecks={changeBackgroundChecks}
                    advancedChecks={advancedChecks} changeAdvancedChecks={changeAdvancedChecks}
                  />
                ))}
              </>
            }
          </div>
        </div>
      </section>

      <section id="stickyPricing" className={`${styles.total} section`}>
        <div className="container">
          <div className="button">
            <ul>
              <li>
                <a href="https://account.myamplify.io/signup">Start Free Trial</a>
              </li>
            </ul>
          </div>
          <div className="numbers">
            <div className="frame">
              <div className="bundleUsage">
                <div className="bundle">
                  Bundle Price:&nbsp; <div><strong>$<span id="bundlePrice">75</span></strong> <span className="symbol">+</span></div>
                </div>
                <div className="usage">
                  Estimated Usage:&nbsp; <div><strong>$<span id="estimatedUsage">0</span></strong> <span className="symbol">=</span></div>
                </div>
              </div>
              <div className="total">
                Estimated Monthly Total:&nbsp; <div><strong>$<span id="estimatedTotal">75</span></strong> <span className="asterik">*</span></div>
              </div>
            </div>
          </div>
          <div className="disclaimer">
            *The price provided is an estimate. Actual prices may vary. <a href="https://account.myamplify.io">Already A Customer?</a>
          </div>
        </div>
      </section>

      <div className={styles.spacer} />

    </>
  )
}

export default AmplifyPricing
