import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import fetchJsonp from 'fetch-jsonp'

const PSORoadshow = ({handler, payment, success}) => {
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    job_title: '',
    company: '',
    city: '',
    state: '',
    country: 'US',
    zip: '',
    partner_meeting_dates: [],
    questions_comments: '',
    referring_page: window.location.href,
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    utm_term: ''
  };
  
  let validationShape = {
    firstname: Yup.string()
      .required('Required'),
    lastname: Yup.string()
      .required('Required'),
    email: Yup.string()
      .email('Must be a valid email')
      .required('Required'),
    phone: Yup.string()
      .required('Required'),
    job_title: Yup.string()
      .required('Required'),
    company: Yup.string()
      .required('Required'),
    city: Yup.string()
      .required('Required'),
    state: Yup.string()
      .required('Required'),
    country: Yup.string()
      .required('Required'),
    zip: Yup.string()
      .required('Required'),
    partner_meeting_dates: Yup.array()
      .min(1, 'Select at least one date')
      .max(1, 'Select only one date')
  };

  const validationSchema = Yup.object().shape(validationShape);

  const handlePardot = (values) => {
    if (success) {
      //alert(`${handler}?${new URLSearchParams(values).toString()}`);
      fetchJsonp(`${handler}?${new URLSearchParams(values).toString()}`, {
        jsonpCallbackFunction: 'callbackPardot'
      })
      .then(function(response) {
        return response.json()
      }).then(function(json) {
        console.log('parsed json', json)
        
        setTimeout(function() {
          window.location.assign(payment);
        }, 250);
      }).catch(function(ex) {
        console.log('parsing failed', ex)
      })
    } else {
      //alert(`${handler}?${new URLSearchParams(values).toString()}`);
      fetchJsonp(`${handler}?${new URLSearchParams(values).toString()}`, {
        jsonpCallbackFunction: 'callbackPardot'
      })
      .then(function(response) {
        return response.json()
      }).then(function(json) {
        console.log('parsed json', json)
      }).catch(function(ex) {
        console.log('parsing failed', ex)
        
        setTimeout(function() {
          window.location.assign(payment);
        }, 250);
      })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handlePardot(values);
      }}
    >
      {({ values, errors, touched }) => (
        <Form className="Form">
          <h2>1. Select the date and location you will be attending</h2>
          
          <div className="input-group">
            {/*<label htmlFor="partner_meeting_dates_1" style={{display: 'block', height: 'auto', visibility: 'visible', marginBottom: '1rem'}}>
              <Field id="partner_meeting_dates_1" name="partner_meeting_dates" type="checkbox" value="Feb 20-22 St Johns" style={{marginRight: '0.5em'}} />
              <strong>February 20, 2024 - St. Johns, FL</strong>
              <span style={{display: 'block', marginLeft: '24px'}}>Marywood Retreat and Conference Center<br />
              235 Marywood Drive, St. Johns, FL 32259 | 904.287.2525</span>
              <span className="rsvp" style={{display: 'block', marginLeft: '24px'}}>Please RSVP by February 1, 2024</span>
            </label>*/}
            
            <label htmlFor="partner_meeting_dates_2" style={{display: 'block', height: 'auto', visibility: 'visible', marginBottom: '1rem'}}>
              <Field id="partner_meeting_dates_2" name="partner_meeting_dates" type="checkbox" value="Apr 23-25 Dallas" style={{marginRight: '0.5em'}} />
              <strong>April 23, 2024 - Houston, TX</strong>
              <span style={{display: 'block', marginLeft: '24px'}}>Co-hosted by the Archdiocese of Galveston-Houston<br />
              Courtyard by Marriott Houston Downtown<br />
              916 Dallas St, Houston, TX  77002 | 832-366-1600</span>
              <span className="rsvp" style={{display: 'block', marginLeft: '24px'}}>Please RSVP by April 4, 2024</span>
            </label>
            <ErrorMessage name="partner_meeting_dates" render={msg => <span className="error error-large">{msg}</span>} />
          </div>

          <h2>2. Diocese or Parish Information</h2>

          <div>
            <label htmlFor="company">Diocese or Parish Name</label>
            <Field name="company" type="text" placeholder="Diocese or Parish Name" />
            <ErrorMessage name="company" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div className="input-group">
            <div>
              <label htmlFor="address_one">Address Line 1</label>
              <Field name="address_one" type="text" placeholder="Address Line 1" />
              <ErrorMessage name="address_one" render={msg => <span className="error">{msg}</span>} />
            </div>

            <div>
              <label htmlFor="address_two">Address Line 2</label>
              <Field name="address_two" type="text" placeholder="Address Line 2" />
              <ErrorMessage name="address_two" render={msg => <span className="error">{msg}</span>} />
            </div>
          </div>

          <div>
            <label htmlFor="city">City</label>
            <Field name="city" type="text" placeholder="City" />
            <ErrorMessage name="city" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div className="input-group">
            <div>
              <label htmlFor="state">State</label>
              <Field name="state" as="select">
                <option value="">State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </Field>
              <ErrorMessage name="state" render={msg => <span className="error">{msg}</span>} />
            </div>

            <div>
              <label htmlFor="country">Country</label>
              <Field name="country" type="text" placeholder="Country" />
              <ErrorMessage name="country" render={msg => <span className="error">{msg}</span>} />
            </div>

            <div>
              <label htmlFor="zip">Zip Code</label>
              <Field name="zip" type="text" placeholder="Zip Code" />
              <ErrorMessage name="zip" render={msg => <span className="error">{msg}</span>} />
            </div>
          </div>

          <div>
            <label htmlFor="phone">Phone</label>
            <Field name="phone" type="text" placeholder="Phone" />
            <ErrorMessage name="phone" render={msg => <span className="error">{msg}</span>} />
          </div>
          
          <h2>3. Attendee Information</h2>

          <div className="input-group">
            <div>
              <label htmlFor="firstname">First Name</label>
              <Field name="firstname" type="text" placeholder="First Name" />
              <ErrorMessage name="firstname" render={msg => <span className="error">{msg}</span>} />
            </div>

            <div>
              <label htmlFor="lastname">Last Name</label>
              <Field name="lastname" type="text" placeholder="Last Name" />
              <ErrorMessage name="lastname" render={msg => <span className="error">{msg}</span>} />
            </div>
          </div>

          <div>
            <label htmlFor="email">Email</label>
            <Field name="email" type="email" placeholder="Email" />
            <ErrorMessage name="email" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="job_title">Job Title</label>
            <Field name="job_title" type="text" placeholder="Job Title" />
            <ErrorMessage name="job_title" render={msg => <span className="error">{msg}</span>} />
          </div>
          
          <div>
            <label htmlFor="questions_comments">Any food requirements?</label>
            <Field name="questions_comments" type="text" placeholder="Any food requirements?" />
            <ErrorMessage name="questions_comments" render={msg => <span className="error">{msg}</span>} />
          </div>

          <Field name="utm_campaign" type="hidden" />
          <Field name="utm_source" type="hidden" />
          <Field name="utm_medium" type="hidden" />
          <Field name="utm_content" type="hidden" />
          <Field name="utm_term" type="hidden" />

          <div className="input-submit">
            <button type="submit" className="Btn">
              Continue to Payment
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PSORoadshow;