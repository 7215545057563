import Banner from './Banner';
import Homepage from './Homepage';
import Landing from './Landing';
import Demo from './Demo';
import AmplifyPricing from './Pricing';

const BrandUni = (data) => {
  const type = data.type;

  if (type === 'Banner') {
    return <Banner {...data} />;
  }

  if (type === 'Homepage') {
    return <Homepage {...data} />;
  }

  if (type === 'Landing') {
    return <Landing {...data} />;
  }

  if (type === 'Demo') {
    return <Demo {...data} />;
  }

  if (type === 'Pricing') {
    return <AmplifyPricing {...data} />;
  }
};

export default BrandUni;