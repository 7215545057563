import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import fetchJsonp from 'fetch-jsonp'
import { GetQueryParam } from '../Utils.js'

const PardotForm = ({handler, date, endpoint, redirect, inline, first, last, phone, company, title, country, state, zip, attendance, screenings, customer, catholic, comments_type, comments, consent, consent_color, partner, button, button_color, success}) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  let actionURL = handler;

  if (handler === undefined) {
    let domain = 'go.ministrybrands.com';
    domain += '/l/991222/';
    actionURL = 'https://' + domain + date + '/' + endpoint;
  }

  let partnerEncoded = GetQueryParam('ps_partner_key');
  let partnerDecoded = atob(partnerEncoded);

  let gclid = GetQueryParam('gclid');
  let utm_campaign = GetQueryParam('utm_campaign');
  let utm_source = GetQueryParam('utm_source');
  let utm_medium = GetQueryParam('utm_medium');
  let utm_content = GetQueryParam('utm_content');
  let utm_term = GetQueryParam('utm_term');

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    country: '',
    state: '',
    zip: '',
    weekly_attendance: '',
    screenings_per_year: '',
    mb_customer: '',
    parish_or_dio: '',
    questions_comments: '',
    job_title: '',
    mb_terms: true
  };
  
  let validationShape = {
    email: Yup.string()
      .email('Must be a valid email')
      .required('Required')
  };

  if (first) {
    validationShape.firstname = Yup.string()
      .required('Required')
  }

  if (last) {
    validationShape.lastname = Yup.string()
      .required('Required')
  }

  if (phone) {
    validationShape.phone = Yup.string()
      .required('Required')
  }

  if (company) {
    validationShape.company = Yup.string()
      .required('Required')
  }

  if (country) {
    validationShape.country = Yup.string()
      .required('Required');
    validationShape.state = Yup.string()
      .required('Required')
  }

  if (state) {
    validationShape.state = Yup.string()
      .required('Required')
  }

  if (zip) {
    validationShape.zip = Yup.string()
      .matches(/^\d{5}([-]\d{4})?$/, 'Incorrect format')
      .required('Required')
  }

  if (attendance) {
    validationShape.weekly_attendance = Yup.string()
      .required('Required')
  }

  if (screenings) {
    validationShape.screenings_per_year = Yup.string()
      .required('Required')
  }

  if (customer) {
    validationShape.mb_customer = Yup.string()
      .required('Required')
  }

  if (catholic) {
    validationShape.parish_or_dio = Yup.string()
      .required('Required')
  }

  if (comments || comments_type) {
    validationShape.questions_comments = Yup.string()
      .required('Required')
  }

  if (title) {
    validationShape.job_title = Yup.string()
      .required('Required')
  }

  if (consent) {
    validationShape.mb_terms = Yup.bool()
      .oneOf([true], 'Required')
  }

  const validationSchema = Yup.object().shape(validationShape);

  const handlePardot = (values) => {
    let pardotField = {};

    if (handler) {
      pardotField = {
        email: values.email
      }
    } else {
      pardotField = {
        [endpoint+'_email']: values.email
      }
    }

    if (first) {
      pardotField.firstname = values.firstname
    }

    if (last) {
      pardotField.lastname = values.lastname
    }

    if (phone) {
      pardotField.phone = values.phone
    }

    if (company) {
      pardotField.company = values.company
    }

    if (title) {
      pardotField.job_title = values.job_title
    }

    if (country) {
      pardotField.country = values.country;
      pardotField.state = values.state
    }

    if (state) {
      pardotField.state = values.state
    }

    if (zip) {
      pardotField.zip = values.zip
    }

    if (attendance) {
      pardotField.weekly_attendance = values.weekly_attendance
    }

    if (screenings) {
      pardotField.screenings_per_year = values.screenings_per_year
    }

    if (customer) {
      pardotField.mb_customer = values.mb_customer
    }

    if (catholic) {
      pardotField.parish_or_dio = values.parish_or_dio
    }

    if (comments) {
      pardotField.questions_comments = values.questions_comments
    }

    if (consent) {
      pardotField.mb_terms = values.mb_terms
    }

    if (partner) {
      pardotField.partner_name = partner
    }

    if (partnerDecoded) {
      pardotField.partner_name = partnerDecoded
    }

    if (gclid) {
      pardotField.gclid = gclid
    }

    if (utm_campaign) {
      pardotField.utm_campaign = utm_campaign
    }

    if (utm_source) {
      pardotField.utm_source = utm_source
    }

    if (utm_medium) {
      pardotField.utm_medium = utm_medium
    }

    if (utm_content) {
      pardotField.utm_content = utm_content
    }

    if (utm_term) {
      pardotField.utm_term = utm_term
    }

    pardotField.referring_page = url

    const pardotData = pardotField;

    if (success) {
      //alert(`${actionURL}?${new URLSearchParams(pardotData).toString()}`);
      fetchJsonp(`${actionURL}?${new URLSearchParams(pardotData).toString()}`, {
        jsonpCallbackFunction: 'callbackPardot'
      })
      .then(function(response) {
        return response.json()
      }).then(function(json) {
        console.log('parsed json', json)
        
        setTimeout(function() {
          if (redirect) {
            window.location.assign(redirect);
          } else if (inline && inline.length > 0) {
            document.getElementById('mb-pardot').innerHTML = inline;
          } else if (inline) {
            document.getElementById('mb-pardot').innerHTML = '<p>Thank you for your submission.</p>';
          } else {
            document.getElementById('mb-pardot').innerHTML = '';
          }
        }, 250);
      }).catch(function(ex) {
        console.log('parsing failed', ex)
      })
    } else {
      //alert(`${actionURL}?${new URLSearchParams(pardotData).toString()}`);
      fetchJsonp(`${actionURL}?${new URLSearchParams(pardotData).toString()}`, {
        jsonpCallbackFunction: 'callbackPardot'
      })
      .then(function(response) {
        return response.json()
      }).then(function(json) {
        console.log('parsed json', json)
      }).catch(function(ex) {
        console.log('parsing failed', ex)
        
        setTimeout(function() {
          if (redirect) {
            window.location.assign(redirect);
          } else if (inline && inline.length > 0) {
            document.getElementById('mb-pardot').innerHTML = inline;
          } else if (inline) {
            document.getElementById('mb-pardot').innerHTML = '<p>Thank you for your submission.</p>';
          } else {
            document.getElementById('mb-pardot').innerHTML = '';
          }
        }, 250);
      })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handlePardot(values);
      }}
    >
      {({ errors, touched, values }) => (
        <Form className="Form">
          {first &&
          <div>
            <label htmlFor="firstname">First Name</label>
            <Field name="firstname" type="text" placeholder="First Name" />
            <ErrorMessage name="firstname" render={msg => <span className="error">{msg}</span>} />
          </div>
          }

          {last &&
          <div>
            <label htmlFor="lastname">Last Name</label>
            <Field name="lastname" type="text" placeholder="Last Name" />
            <ErrorMessage name="lastname" render={msg => <span className="error">{msg}</span>} />
          </div>
          }

          <div>
            <label htmlFor="email">Email</label>
            <Field name="email" type="email" placeholder="Email" />
            <ErrorMessage name="email" render={msg => <span className="error">{msg}</span>} />
          </div>

          {phone &&
          <div>
            <label htmlFor="phone">Phone</label>
            <Field name="phone" type="text" placeholder="Phone" />
            <ErrorMessage name="phone" render={msg => <span className="error">{msg}</span>} />
          </div>
          }

          {company &&
          <div>
            <label htmlFor="company">{company === 'true' ? 'Organization Name' : company}</label>
            <Field name="company" type="text" placeholder={company === 'true' ? 'Organization Name' : company} />
            <ErrorMessage name="company" render={msg => <span className="error">{msg}</span>} />
          </div>
          }

          {title &&
          <div>
            <label htmlFor="job_title">Job Title</label>
            <Field name="job_title" type="text" placeholder="Job Title" />
            <ErrorMessage name="job_title" render={msg => <span className="error">{msg}</span>} />
          </div>
          }

          {country &&
          <div>
            <label htmlFor="country">Country</label>
            <Field name="country" as="select">
              <option value="">Country</option>
              <option value="CA">Canada</option>
              <option value="US">United States</option>
            </Field>
            <ErrorMessage name="country" render={msg => <span className="error">{msg}</span>} />
          </div>
          }

          {(country || state) &&
          <div>
            <label htmlFor="state">{values.country === 'CA' ? 'Province/Territory' : 'State'}</label>
            <Field name="state" as="select">
              <option value="">{values.country === 'CA' ? 'Province/Territory' : 'State'}</option>

              {values.country === 'CA' ?
              <>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NT">Northwest Territories</option>
                <option value="NS">Nova Scotia</option>
                <option value="NU">Nunavut</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon</option>
              </>
              :
              <>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </>
              }
            </Field>
            <ErrorMessage name="state" render={msg => <span className="error">{msg}</span>} />
          </div>
          }

          {zip &&
          <div>
            <label htmlFor="zip">Zip Code</label>
            <Field name="zip" type="text" placeholder="Zip Code" />
            <ErrorMessage name="zip" render={msg => <span className="error">{msg}</span>} />
          </div>
          }

          {attendance &&
            <div>
              <label htmlFor="weekly_attendance">Total Weekly Attendance</label>
              <Field name="weekly_attendance" as="select">
                <option value="">Total Weekly Attendance</option>
                <option value="100 or less">100 or less</option>
                <option value="101-250">101-250</option>
                <option value="251-500">251-500</option>
                <option value="500+">500+</option>
              </Field>
              <ErrorMessage name="weekly_attendance" render={msg => <span className="error">{msg}</span>} />
            </div>
          }

          {screenings &&
            <div>
              <label htmlFor="screenings_per_year">Screenings Per Year</label>
              <Field name="screenings_per_year" type="text" placeholder="Screenings Per Year" />
              <ErrorMessage name="screenings_per_year" render={msg => <span className="error">{msg}</span>} />
            </div>
          }

          {customer &&
          <div>
            <label htmlFor="mb_customer">Are you a current customer of Ministry Brands?</label>
            <Field name="mb_customer" as="select">
              <option value="">Are you a current customer of Ministry Brands?</option>
              <option value="Is Customer">I am a current customer of Ministry Brands</option>
              <option value="Is Not Customer">I am not a current customer (or I don't know)</option>
            </Field>
            <ErrorMessage name="mb_customer" render={msg => <span className="error">{msg}</span>} />
          </div>
          }

          {catholic &&
          <div>
            <label htmlFor="parish_or_dio">Are you a Parish or a Diocese?</label>
            <Field name="parish_or_dio" as="select">
              <option value="">Are you a Parish or a Diocese?</option>
              <option value="Parish">Parish</option>
              <option value="Diocese">Diocese</option>
            </Field>
            <ErrorMessage name="parish_or_dio" render={msg => <span className="error">{msg}</span>} />
          </div>
          }

          {(comments || comments_type) &&
            <div>
              <label htmlFor="questions_comments">{(comments === undefined || comments === 'true') ? 'How did you hear about us?' : comments}</label>
              <Field name="questions_comments" type="text" as={comments_type === 'textarea' ? 'textarea' : ''} placeholder={(comments === undefined || comments === 'true') ? 'How did you hear about us?' : comments} />
              <ErrorMessage name="questions_comments" render={msg => <span className="error">{msg}</span>} />
            </div>
          }

          {consent &&
            <div>
              <label htmlFor="mb_terms" style={{display: 'block', height: 'auto', visibility: 'visible', marginBottom: '1rem', color: consent_color}}>
                <Field name="mb_terms" type="checkbox" style={{marginRight: '0.5em'}} />
                {consent.length > 0 ? consent : 'I acknowledge that Ministry Brands will use my information to contact me about relevant content, products and services and that I can unsubscribe from these emails at any time.'}
              </label>
              <ErrorMessage name="mb_terms" render={msg => <span className="error">{msg}</span>} />
            </div>
          }

          <div style={{textAlign: 'center'}}>
            <button type="submit" className="Btn" style={{backgroundColor: button_color}}>
              {button ? button : 'Submit'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PardotForm;
