import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import fetchJsonp from 'fetch-jsonp'

const FormUpgrade = ({brand, redirect}) => {
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    zip: '',
    mb_customer: '',
    mb_t_and_c: true,
    referring_page: window.location.href,
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    utm_term: ''
  };
  
  let validationShape = {
    firstname: Yup.string()
      .required('Required'),
    lastname: Yup.string()
      .required('Required'),
    email: Yup.string()
      .email('Must be a valid email')
      .required('Required'),
    zip: Yup.string()
      .matches(/^\d{5}([ \-]\d{4})?$/, 'Incorrect format')
      .required('Required'),
    mb_customer: Yup.string()
      .required('Required'),
    mb_t_and_c: Yup.bool()
      .oneOf([true], 'Required')
  };

  const validationSchema = Yup.object().shape(validationShape);

  let handler = '';
  if (brand === 'MinistryLINQ' || brand === 'GivingKiosk' || brand === 'SimpleGive' || brand === 'eGiving' || brand === 'EFT Plus' || brand === 'NCS Services') {
    handler = 'https://go.ministrybrands.com/l/991222/2023-11-20/2hgzx';
  } else if (brand === 'BlueFire' || brand === 'Mogiv' || brand === 'Clover' || brand === 'Clover Give' || brand === 'GivingBase' || brand === 'e360 Giving' || brand === 'eGive USA') {
    handler = 'https://go.ministrybrands.com/l/991222/2023-11-20/2hh1j';
  }

  const handlePardot = (values) => {
    //alert(`${handler}?${new URLSearchParams(values).toString()}`);
    fetchJsonp(`${handler}?${new URLSearchParams(values).toString()}`, {
      jsonpCallbackFunction: 'callbackPardot'
    })
    .then(function(response) {
      return response.json()
    }).then(function(json) {
      console.log('parsed json', json)
          
      setTimeout(function() {
        document.getElementById('BrandUniSecureForm').innerHTML = '<p>Thank you. We\'ll be in touch.</p>';
      }, 250);
    }).catch(function(ex) {
      console.log('parsing failed', ex)
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handlePardot(values);
      }}
    >
      {({ errors, touched }) => (
        <Form className="Form">
          <div>
            <label htmlFor="firstname">First Name</label>
            <Field name="firstname" type="text" placeholder="First Name" />
            <ErrorMessage name="firstname" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="lastname">Last Name</label>
            <Field name="lastname" type="text" placeholder="Last Name" />
            <ErrorMessage name="lastname" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="email">Email</label>
            <Field name="email" type="email" placeholder="Email" />
            <ErrorMessage name="email" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="zip">Zip Code</label>
            <Field name="zip" type="text" placeholder="Zip Code" />
            <ErrorMessage name="zip" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="mb_customer">Are you a current customer of Ministry Brands?</label>
            <Field name="mb_customer" as="select">
              <option value="">Are you a current customer of Ministry Brands?</option>
              <option value="Is Customer">I am a current customer of Ministry Brands</option>
              <option value="Is Not Customer">I am not a current customer (or I don't know)</option>
            </Field>
            <ErrorMessage name="mb_customer" render={msg => <span className="error">{msg}</span>} />
          </div>
          
          <div>
            <label htmlFor="mb_t_and_c" style={{display: 'block', height: 'auto', visibility: 'visible', marginBottom: '1rem'}}>
              <Field name="mb_t_and_c" type="checkbox" style={{marginRight: '0.5em'}} />
              I acknowledge that Ministry Brands will use my information to contact me about relevant content, products and services and that I can unsubscribe from these emails at any time.
            </label>
            <ErrorMessage name="mb_t_and_c" render={msg => <span className="error">{msg}</span>} />
          </div>

          <button type="submit" className="Btn">Secure My Spot</button>
        </Form>
      )}
    </Formik>
  );
};

export default FormUpgrade;