import PardotForm from './Form';
import Contact from './Contact';
//import GivingSignup from './GivingSignup';
import PMMPartner from './PMMPartner';
import PYSPartner from './PYSPartner';
import PSOSummit from './PSOSummit';
import PSORoadshow from './PSORoadshow';

const Pardot = (data) => {
  const type = data.type;

  if (type === 'Form') {
    return <PardotForm {...data} />;
  }

  /*if (type === 'GivingSignup') {
    return <GivingSignup {...data} />;
  }*/

  if (type === 'PMMPartner') {
    return <PMMPartner {...data} />;
  }

  if (type === 'PYSPartner') {
    return <PYSPartner {...data} />;
  }

  if (type === 'PSOSummit') {
    return <PSOSummit {...data} />;
  }

  if (type === 'PSORoadshow') {
    return <PSORoadshow {...data} />;
  }
  
  return <Contact {...data} />;
};

export default Pardot;