import * as React from 'react'
//import styles from './index.module.scss';
import * as Icon from '../Icons';
import imgPeople from '../../images/branduni/people.png';
import imgGiving from '../../images/branduni/giving.png';
import imgMobile from '../../images/branduni/mobile.png';
import imgStreaming from '../../images/branduni/streaming.png';
import imgWebsites from '../../images/branduni/websites.png';
import imgProtections from '../../images/branduni/protections.png';
import imgMedia from '../../images/branduni/media.png';
import imgCommunications from '../../images/branduni/communications.png';
import imgAccounting from '../../images/branduni/accounting.png';
import imgServicePlanning from '../../images/branduni/service-planning.png';
import imgServices from '../../images/branduni/services.jpg';
import imgDemo from '../../images/branduni/demo.png';
import video from '../../images/branduni/video.mp4';

const Landing = ({brand}) => {
  React.useEffect(() => {
    function isInView(el) {
      const box = el.getBoundingClientRect();
      return box.top < window.innerHeight && box.bottom >= 0;
    }

    const list = Array.from(document.querySelectorAll('.amplifyList li'));
    const items = Array.from(document.querySelectorAll('.amplifyItem'));

    list.forEach((el, i) => {
      el.addEventListener('click', () => {
        list.forEach(el => el.classList.remove('active'));
        items.forEach(el => el.classList.remove('active'));

        list[i].classList.add('active');
        items[i].classList.add('active');
      });
    });
  }, []);

  return (
    <>
      <div className="BrandUniLanding">
        <section className="Hero section">
          <div className="container">
            <div className="Hero__headline">
              <h2>Empowering Healthy Churches <strong>with Ministry Brands Amplify +</strong></h2>
              <p>The only end-to-end church ops solutions that deliver impactful insights your entire ministry, mission, and members will love.</p>
              <div className="Hero__headline-cta">
                <a href="https://account.myamplify.io/signup" target="_blank" rel="noreferrer" className="Btn">Get Free Trial</a>
              </div>
            </div>
          </div>
          <div className="container" style={{maxWidth: '1920px'}}>
            <div className="Hero__image">
              <video width="100%" height="auto" loop autoPlay muted>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </section>

        <section className="Stats section">
          <div className="container">
            <div className="Stats__frame">
              <div className="columns is-vcentered">
                <div className="Stats__headline column">
                  40 years of innovative solutions helping amplify the greatest story ever.
                </div>
                <div className="Stats__values column">
                  <ul>
                    <li>
                      <span>2X</span>
                      More Giving
                    </li>
                    <li>
                      <span>$6.45B</span>
                      Charitable giving managed annually
                    </li>
                    <li>
                      <span>42K</span>
                      Websites built for churches of all sizes
                    </li>
                    <li>
                      <span>90,000+</span>
                      Customers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="Amplify section">
          <div className="container">
            <div className="Amplify__headline">
              <h2>Welcome to <strong>Ministry Brands Amplify +</strong></h2>
            </div>
            <div className="columns" style={{marginBottom: '0'}}>
              <div className="column" style={{paddingTop: '0', paddingBottom: '0'}}>
                <div className="Amplify__intro">
                  <h3>Impactful Insights that Empower Your Entire Church</h3>
                  <p>Ministry Brands Amplified + is the ultimate church operations solution crafted from the DNA of our leading church-focused brands like Shelby, easyTithe, and FellowshipOne.</p>
                  <p>Seamlessly manage all aspects of your ministry, mission, and members, unlocking impactful insights that transcend engagement, empowering you to amplify the greatest story ever told.</p>
                </div>
              </div>
            </div>

            <div className="Modules">
              <div className="list">
                <ul className="amplifyList">
                  <li className="active">
                    <img src={Icon.People} alt="Icon" />
                    <span>People</span>
                  </li>
                  <li>
                    <img src={Icon.Giving} alt="Icon" />
                    <span>Giving</span>
                  </li>
                  <li>
                    <img src={Icon.Mobile} alt="Icon" />
                    <span>Mobile App</span>
                  </li>
                  <li>
                    <img src={Icon.Websites} alt="Icon" />
                    <span>Websites</span>
                  </li>
                  <li>
                    <img src={Icon.Streaming} alt="Icon" />
                    <span>Streaming</span>
                  </li>
                  <li>
                    <img src={Icon.ServicePlanning} alt="Icon" />
                    <span>Service Planning</span>
                  </li>
                  <li>
                    <img src={Icon.Accounting} alt="Icon" />
                    <span>Accounting</span>
                  </li>
                  <li>
                    <img src={Icon.Protections} alt="Icon" />
                    <span>Safety</span>
                  </li>
                  <li>
                    <img src={Icon.Media} alt="Icon" />
                    <span>Media</span>
                  </li>
                  <li>
                    <img src={Icon.Communications} alt="Icon" />
                    <span>Communications</span>
                  </li>
                  <li>
                    <img src={Icon.Services} alt="Icon" />
                    <span>Services</span>
                  </li>
                </ul>
              </div>
              <div className={`item amplifyItem active`}>
                <div className="content">
                  <h3>People</h3>
                  <p><strong> A Smarter Way to Understand & Engage with Your Members.</strong></p>
                  <p>Get the impactful insights and automation that empower you to go beyond "engagement" and truly understand your members, guiding them along their faith journey, and helping amplify the greatest story ever told.</p>
                </div>
                <div className="image">
                  <img src={imgPeople} alt="Graphic" />
                </div>
              </div>
              <div className={`item amplifyItem`}>
                <div className="content">
                  <h3>Giving</h3>
                  <p><strong>A Proven Giving Solution that Amplifies Generosity</strong></p>
                  <p>Ministry Brands Amplify Giving helps drive over $6.4 billion in annual donations, empowering your ministry, mission, and members while amplifying the word of the lord.</p>
                  <p><strong>Did you know?</strong> Churches that combine our people and giving solutions typically see 2x their Giving.</p>
                </div>
                <div className="image">
                <img src={imgGiving} alt="Graphic" />
                </div>
              </div>
              <div className={`item amplifyItem`}>
                <div className="content">
                  <h3>Mobile App</h3>
                  <p><strong>Empower Limitless Engagement with Your Church, Creating A Deeper Connection with God.</strong></p>
                  <p>Our Mobile App Builder is dedicated to weaving God and your Ministry into the fabric of daily life, keeping your members spiritually connected.</p>
                  <p>Seamlessly manage your member data, donations, check-ins, and content delivery with ease—personalized push notifications help foster deeper engagement.</p>
                  <p>Transform your ministry into an immersive journey with a seamless, enriching mobile experience. Let devotion thrive in the palm of their hands.</p>
                </div>
                <div className="image">
                <img src={imgMobile} alt="Graphic" />
                </div>
              </div>
              <div className={`item amplifyItem`}>
                <div className="content">
                  <h3>Websites</h3>
                  <p><strong>Websites Designed to Amplify Your Church and the Greatest Story Ever Told.</strong></p>
                  <p>Craft websites that effortlessly empower your community and amplify the word of the lord with ready-to-edit templates and custom-built solutions. Explore our user-friendly drag-and-drop builder and WordPress's Elementor-driven design, or opt for a fully custom website designed by our experts. Manage multiple websites seamlessly in one account, amplifying your church's reach.</p>
                </div>
                <div className="image">
                <img src={imgWebsites} alt="Graphic" />
                </div>
              </div>
              <div className={`item amplifyItem`}>
                <div className="content">
                  <h3>Streaming</h3>
                  <p><strong>Extend Your Ministry's Reach and Members' Engagement with Seamless Online Streaming.</strong></p>
                  <p>Engage your congregation with interactive live streams with real-time chat, prayer requests, and sermon notes.</p>
                  <p>Our adaptable streaming solution ensures flawless viewing and participation of your worship services and events anywhere and anytime. Take command with robust content management tools, effortlessly editing live, simulated live, and offline content. Redefine the future of worship with an innovative solution technology that meets spirituality for an unparalleled connection.</p>
                </div>
                <div className="image">
                <img src={imgStreaming} alt="Graphic" />
                </div>
              </div>
              <div className={`item amplifyItem`}>
                <div className="content">
                  <h3>Service Planning</h3>
                  <p><strong>Plan it. Schedule it. Enjoy it.</strong></p>
                  <p>Empower your worship and volunteer teams to effortlessly deliver services and events that impact your ministry, mission, & members.</p>
                </div>
                <div className="image">
                <img src={imgServicePlanning} alt="Graphic" />
                </div>
              </div>
              <div className={`item amplifyItem`}>
                <div className="content">
                  <h3>Accounting</h3>
                  <p><strong>Church Accounting that Makes Sense and Saves You Cents.</strong></p>
                  <p>Your church needs healthy finances to fulfill its mission. Our integrated accounting solution by Shelby Systems has been helping churches be the best stewards of their finances for over 40 years.</p>
                  <p>Seamlessly manage budgets, payroll, and purchasing with an intuitive interface. Unlock customizable reports and innovative features like Expense Amortization and Fixed Asset Management. Consolidate financial tasks effortlessly. Elevate your church's financial clarity and efficiency, making every cent count.</p>
                </div>
                <div className="image">
                <img src={imgAccounting} alt="Graphic" />
                </div>
              </div>
              <div className={`item amplifyItem`}>
                <div className="content">
                  <h3>Safety</h3>
                  <p><strong>The Standard for Safer Churches.</strong></p>
                  <p>Your church staff, volunteers, and members deserve a safe place to worship and amplify their faith. Enhance your ministry's safety with our industry-leading background screening solution.</p>
                  <p>Trusted by 35,000+ ministries nationwide, we grasp the unique needs of churches, ensuring your congregation's peace of mind. Our robust features, including thorough background checks and real-time criminal monitoring, empower churches to foster safety and shield their community from potential threats.</p>
                </div>
                <div className="image">
                <img src={imgProtections} alt="Graphic" />
                </div>
              </div>
              <div className={`item amplifyItem`}>
                <div className="content">
                  <h3>Media</h3>
                  <p><strong>Church Media Designed to Amplify Your Ministry and Mission Your Way.</strong></p>
                  <p>Ministry Brands is revolutionizing the landscape of church media by bringing together the leading providers in church motion graphics, media, and kids' curriculum with CMG and ShareFaith.</p>
                  <p>Whether a church plant or multi-campus, empower your Ministry to shape a unique brand and voice, authentically reflecting your mission and Amplifying the greatest story ever told.</p>
                </div>
                <div className="image">
                <img src={imgMedia} alt="Graphic" />
                </div>
              </div>
              <div className={`item amplifyItem`}>
                <div className="content">
                  <h3>Communications</h3>
                  <p><strong>Your Church Needs the Chatter that Matters.</strong></p>
                  <p>In today's dynamic environment, where schedules change, events unfold, and safety is a priority, our leading-edge communication solution empowers your church to connect and inform your congregation of the information they need when they need it.</p>
                  <p>Our communication solution goes beyond the conventional, providing not just messages but meaningful connections that strengthen the bonds within your congregation.</p>
                </div>
                <div className="image">
                <img src={imgCommunications} alt="Graphic" />
                </div>
              </div>
              <div className={`item amplifyItem`}>
                <div className="content">
                  <h3>Services</h3>
                  <p><strong>Empower Your Ministry, Mission, and Members to Succeed.</strong></p>
                  <p>Beyond cutting-edge technology, our Success Team offers a wealth of expertise and tailored knowledge to propel you, your staff, volunteers, and members toward unprecedented achievements.</p>
                  <p>Whether you're a dynamic church plant, a thriving mega-church, or integrating new staff, our success team ensures a seamless journey with Ministry Brands Amplify+. Experience the transformation as your church adapts and flourishes under our guidance, fostering health and empowerment at every stage.</p>
                </div>
                <div className={`image stock`}>
                <img src={imgServices} alt="Graphic" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Demo">
          <div className="container">
            <div className="Demo__columns">
              <div className="Demo__image">
                <div className="Demo__image-inner">
                  <div className="Demo__image-wrapper">
                    <img src={imgDemo} alt="Demo" />
                  </div>
                </div>
              </div>
              <div className="Demo__content">
                <div className="Demo__content-inner">
                  <div className="Demo__label">
                    More Solutions, Most Trusted
                  </div>
                  <h2>Ministry Brands Amplify +</h2>
                  <p>Discover why Ministry Brands is trusted by Churches of all sizes—helping them amplify their mission and God by delivering end-to-end impactful insights that matter from every corner of your church.</p>
                  <ul>
                    <li>The only true end-to-end church ops solutions</li>
                    <li>The only solutions empowering healthy churches for over 40 years</li>
                  </ul>
                  <a href="/brand-unification/demo/" className="Btn">Sign Up for a Demo</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
};

export default Landing;