import * as React from 'react'
//import styles from './index.module.scss';
import imgAmplifyLogo from '../../images/branduni/ministry-brands-amplify-logo.svg';
import bgDemo from '../../images/branduni/bg-demo.png';
import video from '../../images/branduni/video.mp4';
import Form from '../BrandUni/Form/Demo';

const Demo = ({brand}) => {
  return (
    <>
      <section className="BrandUniDemo" style={{backgroundImage: 'url(' + bgDemo + ')'}}>
        <div className="container">
          <div className="BrandUniDemo__columns">
            <div className="BrandUniDemo__content">
              <div className="BrandUniDemo__content-inner">
                <img src={imgAmplifyLogo} alt="Logo" />
                <h2>Amplify Ministry Excellence. Tune into a Demo.</h2>
              </div>
              <div className="BrandUniDemo__video">
                <video width="100%" height="auto" loop autoPlay muted>
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="BrandUniDemo__cta">
              <div className="BrandUniDemo__cta-inner">
                <h3>Experience the impact. Schedule Your Free Demo.</h3>
                <div id="BrandUniDemoForm" className="BrandUniDemo__form Form">
                  <Form brand={brand} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default Demo;