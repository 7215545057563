import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import fetchJsonp from 'fetch-jsonp'

const PYSPartner = ({partner}) => {
  const handler = 'https://go.ministrybrands.com/l/991222/2023-03-01/h13y';

  let partnerName = '';

  if (partner != null) {
    partnerName = partner
  }

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    state: '',
    screenings_per_year: '',
    partner_name: partnerName,
    prospect: 'Lead',
    referring_page: window.location.href,
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    utm_term: ''
  };
  
  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('Required'),
    lastname: Yup.string()
      .required('Required'),
    email: Yup.string()
      .email('Must be a valid email')
      .required('Required'),
    phone: Yup.string()
      .required('Required'),
    company: Yup.string()
      .required('Required'),
    state: Yup.string()
      .required('Required'),
    screenings_per_year: Yup.number()
      .typeError('Must be a number')
      .positive()
      .min(1)
      .required('Required')
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        //alert(`${handler}?${new URLSearchParams(values).toString()}`);
        fetchJsonp(`${handler}?${new URLSearchParams(values).toString()}`, {
          jsonpCallbackFunction: 'callbackPardot'
        })
        .then(function(response) {
          return response.json()
        }).then(function(json) {
          console.log('parsed json', json)
          
          setTimeout(function() {
            window.location.assign('https://www.protectyouthsports.com/partners/thankyou/');
          }, 250);
        }).catch(function(ex) {
          console.log('parsing failed', ex)
        })
      }}
    >
      {({ errors, touched }) => (
        <Form className="Form">
          <div>
            <label htmlFor="firstname">First Name</label>
            <Field name="firstname" type="text" placeholder="First Name" />
            <ErrorMessage name="firstname" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="lastname">Last Name</label>
            <Field name="lastname" type="text" placeholder="Last Name" />
            <ErrorMessage name="lastname" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="email">Email</label>
            <Field name="email" type="email" placeholder="Email" />
            <ErrorMessage name="email" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="phone">Phone</label>
            <Field name="phone" type="text" placeholder="Phone" />
            <ErrorMessage name="phone" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="company">Organization Name</label>
            <Field name="company" type="text" placeholder="Organization Name" />
            <ErrorMessage name="company" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="state">State</label>
            <Field name="state" as="select">
              <option value="">State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </Field>
            <ErrorMessage name="state" render={msg => <span className="error">{msg}</span>} />
          </div>

          <div>
            <label htmlFor="screenings_per_year">Screenings Per Year</label>
            <Field name="screenings_per_year" type="text" placeholder="Screenings Per Year" />
            <ErrorMessage name="screenings_per_year" render={msg => <span className="error">{msg}</span>} />
          </div>
          
          <Field name="utm_campaign" type="hidden" />
          <Field name="utm_source" type="hidden" />
          <Field name="utm_medium" type="hidden" />
          <Field name="utm_content" type="hidden" />
          <Field name="utm_term" type="hidden" />

          <button type="submit" className="Btn">Submit</button>
        </Form>
      )}
    </Formik>
  );
};

export default PYSPartner;